import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom/client';
import Spinner from './components/loader/Spinner';
import './index.scss';
import 'bootstrap/dist/css/bootstrap.css';

const LazyApp = lazy(() => import('./App'));

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Suspense fallback={<Spinner />}>
    <LazyApp />
  </Suspense>
);
